import React, { useState, useEffect, useRef } from "react";
import '../App.css'; 
import DatePicker from "react-datepicker";
import { subDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Dropdown from './Dropdown'; 


const stripePromise = loadStripe("pk_live_51NgtgwA91KW339c4m9VPtbTVH0lrZOJh6TmGV0fuFMWYYLXV1JsNw319okGhzPwP4koaJm67Ynh7x6169WQJ7yhy00fUslHkbJ");

function Modal({ message, onClose }) {
    // Function to handle the popup message for the fully booked error
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <p>{message}</p>
                <button onClick={onClose} className="modal-close-btn">Close</button>
            </div>
        </div>
    );
}

function Form() {
    const [pickupLocation, setPickupLocation] = useState('');
    const [dropoffLocation, setDropoffLocation] = useState('');
    const [date, setDate] = useState();
    const [rideDuration, setRideDuration] = useState("");
    const [totalPrice, setTotalPrice] = useState('$0.00'); 
    const [temp, setTemp] = useState('70');
    const [selectedBev, setSelectedBev] = useState("Water");
    const [view, setView] = useState(false); 
    const [roundTrip, setRoundTrip] = useState(true);
    const [vehicleData, setVehicleData] = useState(null); 
    const [driverData, setDriverData] = useState(null); 
    const [checkoutSessionId, setCheckoutSessionId] = useState(null);
    const [pickupLocationError, setPickupLocationError] = useState('');
    const [dropoffLocationError, setDropoffLocationError] = useState('');
    const [rideDurationError, setRideDurationError] = useState('');
    const [dateError, setDateError] = useState('');
    const [tempError, setTempError] = useState('');
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [availabilityError, setAvailabilityError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    // const [toggle, setToggle] = useState(true); 

    const navigate = useNavigate(); // Use navigate to redirect

    const handleEdit = () => { 
        setView(false); // Return to form view
    };

    const roundToggle = () => {
        setRoundTrip(prev => !prev); // Toggle between roundTrip true/false
    };

    // shows dropoff location on second page 
    const showDrop = () => {
        return roundTrip ? pickupLocation : dropoffLocation;
    };

    useEffect(() => {
        // Check if user is signed in
        const email = localStorage.getItem('userEmail');
        if (!email) {
            navigate('/auth'); // Redirect to auth if not signed in
            return;
        }
    }, [navigate]);

    const closeModal = () => {
        setIsModalOpen(false);
        setAvailabilityError(''); // Clear availability error when modal is closed
    };

    const handleSubmit = async (event) => {
        console.log("In handleSubmit");
        event.preventDefault();
        
        setCheckoutSessionId(null); // Reset the session ID when submitting

        const isAddressMatch = (returnedAddress, enteredAddress) => {
            const returnedComponents = returnedAddress.split(',').map(component => component.trim().toLowerCase());
            const enteredComponents = enteredAddress.split(',').map(component => component.trim().toLowerCase());
            return enteredComponents.some(component => returnedComponents.includes(component));
        };

        let isValid = true;

        if (!pickupLocation) {
            setPickupLocationError('Pickup location is required');
            isValid = false;
        } else {
            setPickupLocationError('');
            try {
                const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(pickupLocation)}`);
                const data = await response.json();
                if (data.length === 0 || !isAddressMatch(data[0].display_name, pickupLocation)) {
                    setPickupLocationError('Invalid address');
                    isValid = false;
                }
            } catch (error) {
                console.error('Error validating address:', error);
            }
        }

        if (!roundTrip && !dropoffLocation) {
            setDropoffLocationError('Dropoff location is required for a one-way trip.');
            isValid = false;
        } else {
            setDropoffLocationError('');
        }

        if (!rideDuration) {
            setRideDurationError('Ride Duration is required');
            isValid = false;
        } else {
            setRideDurationError('');
        }

        if (!date) {
            setDateError('Time and date are required');
            isValid = false;
        } else {
            setDateError('');
        }

        if (!temp) {
            setTempError('Temperature is required');
            isValid = false;
        } else {
            setTempError('');
        }

        console.log("Form is valid:", isValid); // Logs validation status
        
        if (!isValid) return; // Stop if form is invalid

        // State updates to show the second page
        setIsFormSubmitted(true);

        setView(true);
        console.log("Form submitted. isFormSubmitted:", isFormSubmitted, "Mode:", "view:", view); // Logs the state changes

        try {
            // Fetch availability and driver/vehicle data from the backend
            const response = await fetch(`https://66ycb6hzw6a3xcj4i2pl67pfcy0traln.lambda-url.us-east-1.on.aws/vehicle_driver_availability?rideDate=${date.toISOString()}&rideDuration=${rideDuration}`);
            const data = await response.json();
            console.log(data);
            if (!data.available) {
                setAvailabilityError('Ridio is fully booked at the time you selected. Please choose another time or day.');
                setIsModalOpen(true);
            } else if (data.vehicle && data.driver) {
                setVehicleData(data.vehicle);
                setDriverData(data.driver);
                setAvailabilityError('');
                setIsFormSubmitted(true); // Set the form as submitted
            } else {
                setAvailabilityError('Unexpected error occurred. Please try again.');
                setIsModalOpen(true);
            }
        } catch (error) {
            setAvailabilityError('Error checking availability.');
            setIsModalOpen(true);
        }
    };

    useEffect(() => {
        if (vehicleData && driverData && isFormSubmitted) {
            createCheckoutSession(); // Proceed with checkout session after data is set
        }
    }, [vehicleData, driverData, isFormSubmitted]);

    const createCheckoutSession = async () => {
        try {
            const response = await fetch('https://66ycb6hzw6a3xcj4i2pl67pfcy0traln.lambda-url.us-east-1.on.aws/checkout', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    pickupLocation,
                    dropoffLocation: roundTrip ? pickupLocation : dropoffLocation,
                    date: date.toISOString(), 
                    rideDuration,
                    temp,
                    selectedBev,
                    vehicleData,
                    driverData,
                    totalPrice
                })
            });
            const data = await response.json();
            if (data && data.id) {
                setCheckoutSessionId(data.id);
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
        }
    };

    const handleCheckout = async () => {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
            sessionId: checkoutSessionId,
        });
        if (error) {
            console.error('Error redirecting to checkout:', error);
        }
    };

    const handleDurationChange = (event) => {
        const duration = event.target.value;
        setRideDuration(duration);
        setTotalPrice(calculatePriceForDuration(duration)); 
    };
    
    const calculatePriceForDuration = (duration) => {
        const basePrice = 60; // Base price for one hour
        const minPricePerHour = 30; // Minimum price per hour
        let totalPrice = 0;
    
        for (let i = 1; i <= duration; i++) {
            let hourPrice;
            if (i === 1) {
                hourPrice = basePrice; // Price for the first hour
            } else {
                hourPrice = basePrice * Math.pow(0.9, i - 1); // Apply 10% discount for each subsequent hour
                if (hourPrice < minPricePerHour) {
                    hourPrice = minPricePerHour; // Ensure price per hour doesn't fall below $30
                }
            }
            totalPrice += hourPrice;
        }
    
        // Round down to the nearest increment of 10
        totalPrice = Math.floor(totalPrice / 10) * 10;
    
        return `$${totalPrice.toFixed(2)}`;
    };


    const handleBevChange = (event) => {
        setSelectedBev(event.target.value);
    };

    const ScrollToErrorMessage = ({ message }) => {
        const errorMessageRef = useRef(null);

        useEffect(() => {
            if (errorMessageRef.current) {
                errorMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, [message]);

        return (
            <div style={{ color: 'red' }} ref={errorMessageRef}>
                {message}
            </div>
        );
    };

    const distance_travelled = rideDuration * 65;
    const gas_efficiency = 25.9;
    const gas_co2_created = 8887;
    const mach_e_efficiency = 0.33;
    const electricity_co2_created = 22.5;
    const grams_red = Math.floor((distance_travelled / gas_efficiency) * gas_co2_created) - ((distance_travelled / mach_e_efficiency) * electricity_co2_created);
    const kilo_round = (grams_red / 1000).toFixed(2);

    return (
        <div>
            {/* Modal */}
            {isModalOpen && (
                <Modal message={availabilityError} onClose={closeModal} />
            )}

            {view ? (
                // "view" refers to the second page, the "view" page
                // "view" starts out false, is made true by the validation function, then made false by the "edit" button, using the handleEdit function

                <div className="App-form">
                    <label className="form-el">
                        <h2>Pricing and Details:</h2>

                        <p>Ride Cost: {totalPrice}</p>
                        <p>This covers {rideDuration} hour(s) of service with a grace period of :30.</p>
                        {pickupLocation !== '' && <p>Pickup Location: {pickupLocation}.</p>}
                        <p>Pickup time: {date ? date.toLocaleString() : ''}</p>
                        <p>Dropoff Location: {showDrop()}</p>
                        <p>Ride Duration: {rideDuration} hour(s)</p>
                        <p>Your preferred temperature is {temp}° fahrenheit.</p>
                        <p>Beverage: {selectedBev}</p>
                        {vehicleData && (
                            <>
                                <p>Vehicle: {vehicleData.vehicleMake} {vehicleData.vehicleModel}</p>
                                <p>Licence plate: {vehicleData.vehicleLicense}</p>
                            </>
                        )}
                        {driverData && (
                            <>
                                <p>Driver's Name: {driverData.driverName}</p>
                                <p>Driver's Phone: {driverData.driverPhone}</p>
                            </>
                        )}
                        <p>Your ride will save approximately {kilo_round} kilograms of CO2 Emissions compared to an average gas car on the same trip!</p>
                        <p>This is an estimate. Total emissions saved will be calculated after your trip has been completed.</p>
                    </label>

                    <label className="button-box second-page">
                        <button className="form-b selected" onClick={handleCheckout}>Book</button>
                        <button className="form-b unselected" onClick={handleEdit}>Edit</button>
                    </label>
                </div>
            ) : (
                <form className="App-form" onSubmit={handleSubmit}>
                    {/* <div style={{ display: toggle ? 'block' : 'none' }}>  */}
                        <label className="form-e">
                            <div className='form-label'>Ride Duration:</div>
                            <select value={rideDuration} className="i-box" onChange={handleDurationChange}>
                                <option className="select" value="">Please choose an option</option>
                                {[1, 2, 3, 4, 6, 8, 24, 48].map((duration) => (
                                    <option key={duration} value={duration}>
                                        {duration} Hour(s) - {calculatePriceForDuration(duration)}
                                    </option>
                                ))}
                            </select>
                            {rideDurationError && <p className="error-message">{rideDurationError}</p>}
                        </label>

                        <label className="form-e">
                            <div className='form-label'>Pickup Location:</div>
                            <input
                                className="i-box"
                                value={pickupLocation}
                                placeholder='Please include punctuation'
                                onChange={e => setPickupLocation(e.target.value)}
                            />
                            {pickupLocationError && <p className="error-message">{pickupLocationError}</p>}
                        </label>

                        <label className="form-e">
                            <div className='form-label'>Pickup Date and Time:</div>
                            <DatePicker
                                className="i-box"
                                showTimeInput
                                minDate={(new Date())}
                                excludeDates={[new Date(), subDays(new Date())]}
                                selected={date}
                                onChange={(date) => setDate(date)}
                                dateFormat="MMMM d, yyyy h:mmaa"
                            />
                            {dateError && <p className="error-message">{dateError}</p>}
                        </label>

                        <label>
                            {/* roundToggle needs to switch between:
                            roundTrip (default)
                                have round trip button have "selected" className,
                                have "roundTrip" boolean save as True,
                                not show dropoffLocation input,
                                not require dropoffLocation for form validation
                            !roundTrip
                                have one way button have selected className
                                have roundtrip save as false
                                show dropoffLocation input
                                require dropoffLocation for form validation */}
                            {roundTrip ? (

                                <div>
                                    <label className="button-box">
                                        <div className="form-b selected round-way" onClick={roundToggle}>Round Trip</div>
                                        <div className="form-b unselected round-way" onClick={roundToggle}>One Way</div>
                                    </label>
                                </div>

                            ) : (

                                <div>
                                    <label className="button-box">
                                        <div className="form-b unselected round-way" onClick={roundToggle}>Round Trip</div>
                                        <div className="form-b selected round-way" onClick={roundToggle}>One Way</div>
                                    </label>

                                    <div className="form-e">
                                        <div className='form-label'>Dropoff Location:</div>
                                        <input
                                            className="i-box"
                                            value={dropoffLocation}
                                            onChange={e => setDropoffLocation(e.target.value)}
                                        />
                                        {dropoffLocationError && <p className="error-message">{dropoffLocationError}</p>}
                                    </div>
                                </div>
 
                            )}
                        </label>

                        <label className="form-e">
                            <div className='form-label'>Selected temperature:</div>
                            <input
                                className="i-box"
                                value={temp}
                                onChange={e => setTemp(e.target.value)}
                                type="number"
                                min="50"
                                max="90"
                            />
                            {tempError && <p className="error-message">{tempError}</p>}
                        </label>

                        <label className="form-e">
                            <div className='form-label'>Complimentary Beverage:</div>
                            <select value={selectedBev} className="i-box" onChange={handleBevChange}>
                                <option value="Water">Water</option>
                                <option value="Iced Tea">Iced Tea</option>
                                <option value="Iced Coffee">Iced Coffee</option>
                            </select>
                        </label>

                        <label className="b-box">
                            <button className="form-b selected confirm-b" type="submit">Confirm your ride?</button>
                        </label>
                </form>
            )}
        </div>
    );
}

export default Form;
