import React, { useEffect, useState } from 'react';
import '../App.css'; 
import { useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";

const formatRideDate = (rideDate) => {
    const date = new Date(rideDate);

    const options = {
        timeZone: 'America/Los_Angeles',
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };

    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    return formattedDate.replace(', ', 'th, ').replace(':00', '');
};

const Profile = () => {
    const [userName, setUserName] = useState('');
    const [registrationDate, setRegistrationDate] = useState('');
    const [upcomingRides, setUpcomingRides] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const email = localStorage.getItem('userEmail');
        if (!email) {
            navigate('/auth');
            return;
        }

        fetch(`https://ol1jnjm4s0.execute-api.us-east-1.amazonaws.com/credential?email=${encodeURIComponent(email)}`)
            .then(response => response.json())
            .then(async data => {
                setUserName(data.name);
                setRegistrationDate(data.registrationDate);
                const rideDetails = await fetchRideDetails(data.rides);
                setUpcomingRides(rideDetails);
            })
            .catch(error => {
                console.error('Error fetching user details:', error);
                navigate('/');
            });
    }, [navigate]);

    const fetchRideDetails = async (rideIds) => {
        const ridePromises = rideIds.map(rideId => {
            return fetch(`https://66ycb6hzw6a3xcj4i2pl67pfcy0traln.lambda-url.us-east-1.on.aws/rides?rideId=${encodeURIComponent(rideId)}`)
                .then(response => response.json())
                .catch(error => {
                    console.error("Error fetching ride details for rideId: ", rideId, error);
                });
        });

        return Promise.all(ridePromises);
    };

    const handleCancelRide = async (rideId) => {
        const confirmCancel = window.confirm("Are you sure you want to cancel this ride?");
        if (confirmCancel) {
            const email = localStorage.getItem('userEmail'); // Get the email from local storage
            console.log("Trying to cancel ride ", rideId);
            try {
                const response = await fetch(`https://66ycb6hzw6a3xcj4i2pl67pfcy0traln.lambda-url.us-east-1.on.aws/cancel`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ rideId, email }) // Pass both rideId and email
                });

                if (response.ok) {
                    alert('Ride canceled successfully.');
                    setUpcomingRides(upcomingRides.filter(ride => ride.ride_id !== rideId));
                } else {
                    alert('Failed to cancel the ride. Please try again.');
                }
            } catch (error) {
                console.error('Error canceling the ride:', error);
                alert('An error occurred while canceling the ride.');
            }
        }
    };

    const convertToCSV = (objArray, section) => {
        let csvContent = `${section}\n`;
        const headers = Object.keys(objArray[0]);
        csvContent += headers.join(',') + '\n';
        
        objArray.forEach(row => {
            const values = headers.map(header => row[header]);
            csvContent += values.join(',') + '\n';
        });

        return csvContent;
    };

    const handleDownloadData = async () => {
        try {
            const email = localStorage.getItem('userEmail');

            // Fetch user data
            const userDataResponse = await fetch(`https://ol1jnjm4s0.execute-api.us-east-1.amazonaws.com/credential?email=${encodeURIComponent(email)}`);
            const userData = await userDataResponse.json();

            // Fetch ride data
            const rideDetails = await fetchRideDetails(userData.rides);

            // Convert user data to CSV
            const userCsvContent = convertToCSV([userData], 'User Data');

            // Convert rides data to CSV
            const ridesCsvContent = convertToCSV(rideDetails, 'Ride Data');

            // Combine both CSV contents into one string
            const combinedCsvContent = userCsvContent + '\n\n' + ridesCsvContent;

            // Create and download the combined CSV
            const blob = new Blob([combinedCsvContent], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement("a");
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", `MyData_${userName}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error retrieving data:', error);
            alert('Failed to retrieve data. Please try again.');
        }
    };


    const handleSignOut = () => {
        localStorage.removeItem('userEmail');
        navigate('/');
    };


    const handleDeleteAccount = async () => {
        const confirmDelete = window.confirm("Are you sure you want to delete your account? This action cannot be undone.");
        if (confirmDelete) {
            const email = localStorage.getItem('userEmail'); // Get the email from local storage
            console.log("Trying to delete account for", email);

            try {
                const response = await fetch(`https://ol1jnjm4s0.execute-api.us-east-1.amazonaws.com/delete-account`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ email }) // Pass the email to identify the user
                });

                if (response.ok) {
                    alert('Account deleted successfully.');
                    localStorage.removeItem('userEmail'); // Remove user data from local storage
                    navigate('/'); // Redirect to the home page after account deletion
                } else {
                    alert('Failed to delete the account. Please try again.');
                }
            } catch (error) {
                console.error('Error deleting the account:', error);
                alert('An error occurred while deleting the account.');
            }
            handleSignOut();
        }
    };


    return (
        <div className='profile-page'>
            <div className="bk-box">
                <NavLink className="form-b selected" to="/book">Book a Ride</NavLink>
            </div>
            <div className="profile-container">
                <h2>Hello {userName}</h2>
                {registrationDate && (
                    <p>Member since {new Date(registrationDate).toLocaleDateString()}</p>
                )}
                <h3>Upcoming Rides:</h3>
                {upcomingRides.length > 0 ? (
                    <table className="rides-table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Pickup Location</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {upcomingRides.map((ride, index) => {
                                console.log("Ride object:", ride);  // Log the entire ride object
                                return (
                                    <tr key={index}>
                                        <td>{formatRideDate(ride.rideDate)}</td>
                                        <td>{ride.pickupLocation}</td>
                                        <td>
                                            <button onClick={() => handleCancelRide(ride.ride_id)} className="cancel-button">
                                                Cancel
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>

                    </table>
                ) : (
                    <p>No upcoming rides</p>
                )}
                <div className="button-container">
                    {/* <button onClick={handleSignOut} className="profile-buttons">Sign Out</button> */}
                    <button onClick={handleSignOut} className="form-b unselected profile-b">Sign Out</button>

                    <button onClick={handleDownloadData} className="form-b unselected profile-b">
                        Download My Data
                    </button>

                    <button onClick={handleDeleteAccount} className="form-b unselected profile-b">
                        Delete My Account
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Profile;
