import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Hamburger from 'hamburger-react';
import "../App.css";

function Header() {
    const [isOpen, setOpen] = useState(false);
    const [isSignedIn, setIsSignedIn] = useState(false);

    useEffect(() => {
        // Check if user is signed in by checking localStorage
        const userEmail = localStorage.getItem('userEmail');
        if (userEmail) {
            setIsSignedIn(true);
        } else {
            setIsSignedIn(false);
        }
    }, []);

    return (
        <div className="App-header">
            {/* logo */}
            <NavLink to="/"><img src={'/rid-logos/Logo_Crop.png'} className="App-logo" alt="Ridio logo featuring energy bolts in the Os" /></NavLink>

            <div onMouseLeave={() => setOpen(false)}>
                {isOpen ? (
                    <div className="headMenu" onMouseLeave={() => setOpen(false)}>
                        <NavLink className="App-link" to="/">Home</NavLink>
                        <NavLink className="App-link" to="/book">Book</NavLink>
                        {/* <NavLink className="App-link" to="/calendar">Calendar</NavLink> */}
                        {isSignedIn ? (
                            <NavLink className="App-link" to="/profile">Profile</NavLink>
                        ) : (
                            <NavLink className="App-link" to="/auth">Sign In</NavLink>
                        )}
                        <NavLink className="App-link" to="/store">Store</NavLink>
                    </div>
                ) : (
                    <div></div>
                )}

                <div className="ham">
                    <Hamburger size={18} toggled={isOpen} toggle={setOpen} />
                </div>
            </div>
        </div>
    );
}

export default Header;
