// will need to style privacy policy popup for responsive design

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import Popup from 'reactjs-popup';


import '../App.css';

const Auth = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [isRegistering, setIsRegistering] = useState(false);
    const navigate = useNavigate();

    const fetchChallenge = async () => {
        const response = await fetch('https://ol1jnjm4s0.execute-api.us-east-1.amazonaws.com/challenge');
        const data = await response.json();
        return Uint8Array.from(atob(data.challenge.replace(/_/g, '/').replace(/-/g, '+')), c => c.charCodeAt(0));
    };

    const checkIfUserExists = async (email) => {
        const response = await fetch(`https://ol1jnjm4s0.execute-api.us-east-1.amazonaws.com/check-email?email=${encodeURIComponent(email)}`);
        const data = await response.json();
        return data.registered;
    };

    const registerPasskey = async () => {
        if (!email || !name || !isChecked) {
            alert('Please enter both email and name, and accept privacy policy.');
            return;
        }

        try {
            // Check if the user is already registered
            const userExists = await checkIfUserExists(email);
            if (userExists) {
                alert('Already registered, please sign in instead.');
                setIsRegistering(false);
                return;
            }

            const challenge = await fetchChallenge();

            const options = {
                rp: { name: 'Ridio' },
                user: {
                    id: new TextEncoder().encode(email),
                    name: email,
                    displayName: name,
                },
                challenge: challenge,
                pubKeyCredParams: [{ type: 'public-key', alg: -7 }],
                authenticatorSelection: { authenticatorAttachment: 'platform', userVerification: 'preferred' },
                attestation: 'direct',
                timeout: 60000,
            };

            const credential = await navigator.credentials.create({ publicKey: options });

            if (credential) {
                const publicKey = btoa(String.fromCharCode(...new Uint8Array(credential.rawId)));
                const response = await fetch('https://ol1jnjm4s0.execute-api.us-east-1.amazonaws.com/register', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        email: email,
                        name: name,
                        publicKey: publicKey  // Store the public key
                    }),
                });

                if (response.status === 201) {
                    alert('User registered successfully.');
                    setIsRegistering(false);
                    localStorage.setItem('userEmail', email);  // Save email to localStorage
                    navigate('/profile'); // Navigate to profile after registration
                } else {
                    const errorText = await response.text();
                    console.error('Registration failed:', errorText);
                    alert('Registration failed.');
                }
            }
        } catch (error) {
            console.error('Error during registration:', error);
            alert('Registration failed.');
        }
    };

    const signInWithPasskey = async () => {
        console.log('signInWithPasskey function called');

        try {
            const challenge = await fetchChallenge();

            const credentialResponse = await fetch(`https://ol1jnjm4s0.execute-api.us-east-1.amazonaws.com/credential?email=${encodeURIComponent(email)}`);
            const credentialResponseText = await credentialResponse.text();

            if (!credentialResponse.ok) {
                console.error('Failed to fetch credential ID:', credentialResponseText);
                alert('Failed to fetch credential ID: ' + credentialResponseText);
                return;
            }

            let credentialData;
            try {
                credentialData = JSON.parse(credentialResponseText);
            } catch (parseError) {
                console.error('Error parsing credential data:', parseError);
                throw new Error('Failed to parse credential ID response');
            }

            const decodedCredentialId = Uint8Array.from(atob(credentialData.credentialId.replace(/_/g, '/').replace(/-/g, '+')), c => c.charCodeAt(0));

            const options = {
                challenge: challenge,
                allowCredentials: [{
                    id: decodedCredentialId,
                    type: 'public-key',
                    transports: ["internal"]
                }],
                userVerification: 'preferred',
            };

            const assertion = await navigator.credentials.get({ publicKey: options });

            alert('User signed in successfully.');
            localStorage.setItem('userEmail', email);  // Save email to localStorage
            navigate('/profile');

        } catch (authError) {
            console.error('Error during sign-in:', authError);
            alert('Sign in failed. ' + authError.message);
        }
    };

    const toggleRegister = () => {
        setIsRegistering(!isRegistering);
    };




    return (
        
        // <div className="auth-container">
        <div className="about-content">
    
            <div className="bk-box">
                <NavLink className="form-b selected" to="/book">Book a Ride</NavLink>
            </div>
            <div className="form-area">

                <form onSubmit={(e) => e.preventDefault()}>
                    <div className='form-e'>
                        <h3 className='auth-header'>{isRegistering ? 'Register' : 'Sign In'}</h3>
                        <input className='i-box' type="email" value={email} placeholder='Email' onChange={(e) => setEmail(e.target.value)} required />
                    </div>

                    {isRegistering && (
                        <span>
                            <div className='form-e'>
                                <input className='i-box' type="text" value={name} placeholder='Name' onChange={(e) => setName(e.target.value)} required />
                            </div>

                            <div className='form-e'>
                                <label className="checkbox-container">
                                    <input 
                                        className={`checkbox ${isChecked ? "checked" : ""}`} 
                                        type="checkbox" 
                                        checked={isChecked} 
                                        onChange={() => setIsChecked(!isChecked)}
                                        required 
                                    />
                                    <div className="check-label">I have read and agree to the Ridio privacy policy.</div>
                                </label>

                                <Popup trigger={<button className='form-b unselected'>Read Privacy Policy</button>} modal>
                                    {close => (
                                        <div className='priv-popup'>
                                            <button className='close' onClick={close}>×</button>
                                            <h3>Privacy Policy</h3>
                                            <h4>Ridio Incorporated</h4>
                                            <p>Last Revised: January 13th, 2024</p>
                                            <p>Ridio Incorporated ("the company", "the provider", "we") are a registered car service in the State of California that offers rides in an electric vehicle with a driver, booked through a website or mobile application. In order to provide this service, the company must retain customer information digitally. This policy governs the terms of the storage and utilization of that information.</p>
                                            <h4>1. Website and Data Terms</h4>
                                            <p>All rides will be booked through the company's website. To book a ride through the website, the customer must create a user profile ("the profile", "the account", "user account").</p>
                                            <p>Some of the information provided during the creation of this profile may be considered sensitive by some users, as may the ride history once the user has taken rides using the service. The company will make best efforts to ensure information security and privacy of this sensitive data. The company is not liable if sensitive or private data is obtained by malicious actors ("threat actors", "adversaries") despite the company's best efforts to stop this from happening.</p>
                                            <p>The company may also utilize third party software, services or other tools not developed by the company to process some data, including customer data. The privacy and data usage policies of these services or tools will be reviewed before they are utilized and best efforts will be made to configure all of them as securely as possible. The company is not liable if a third party does, intentionally or unintentionally, disclose sensitive data despite best efforts on the company's part to ensure that they will not.</p>
                                            <h4>2. Data Portability and Data Deletion</h4>
                                            <p>The company will honor requests to delete personal data from users. The company will also provide all data they have about a user to that user upon request, in an industry standard format wherever possible. This service will be offered free of charge. The company will comply with all applicable laws in the jurisdiction they are incorporated in (the State of California) with respect to customer data.</p>
                                            <h4>3. Data Collection and Storage</h4>
                                            <p>Ridio collects data in a variety of ways, both directly and indirectly. These include but are not limited to data provided by users through website or application forms, website analytics data, vehicle telemetry collected during rides, and information provided in correspondence between Ridio and other parties.</p>
                                            <p>The company will primarily store data in Amazon Web Services (AWS), specifically in the us west-1 region wherever possible, or another AWS region in the United States of America if not possible. Data may also be stored in other locations. Best efforts will be made to utilize industry standard encryption of data wherever possible.</p>
                                            <h4>4. Data Sharing</h4>
                                            <p>Ridio will not share customer data with other customers unless asked to by the customer whose data it is. Ridio will not sell customer data for advertising or other purposes. Ridio will provide data to law enforcement agencies if served with applicable legal process requiring them to do so, or if otherwise required to by law. Ridio will share the minimum amount of customer data needed to ensure quality service with drivers.</p>
                                            <h4>5. Modification of Terms</h4>
                                            <p>The company reserves the right to modify any or all of these terms at any time. Any such modifications will be communicated to users through the best available means of communication on file for that user. The modified terms will also be promptly posted on the website.</p>
                                        </div>
                                    )}
                                </Popup>

                            </div>
                        </span>
                    )}

                    <div className='form-e'>
                        <button className='form-b unselected' onClick={isRegistering ? registerPasskey : signInWithPasskey}>
                            {isRegistering ? 'Register' : 'Sign In'}
                        </button>
                    </div>

                    <div className='form-e'>
                        {isRegistering ? (
                            <span>
                                <h3 className='auth-header'>Already have an account?</h3>
                                <button className='form-b unselected' onClick={() => setIsRegistering(false)}>Sign In</button>
                            </span>
                        ) : (
                            <span>
                                <h3 className='auth-header'>New to Ridio?</h3> 
                                <button className='form-b unselected' onClick={() => setIsRegistering(true)}>Register an account</button>
                            </span>
                        )}
                    </div>

                </form>
            </div>
        </div>
    );
};

export default Auth;
