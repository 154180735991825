import React, { useState } from 'react';
import '../App.css';
import { NavLink } from "react-router-dom";

function PrivPol() {
    return (
        <div className='about-content'>
            <div className="bk-box">
                <NavLink className="form-b selected" to="/book">Book a Ride</NavLink>
            </div>
            <div className='priv-text priv-mobile'>
                <h3>Privacy Policy</h3>
                <h4>Ridio Incorporated</h4>
                <p>Last Revised: January 13th, 2024</p>
                <p>Ridio Incorporated ("the company", "the provider", "we") are a registered car service in the State of California that offers rides in an electric vehicle with a driver, booked through a website or mobile application. In order to provide this service, the company must retain customer information digitally. This policy governs the terms of the storage and utilization of that information.</p>
                <h4>1. Website and Data Terms</h4>
                <p>All rides will be booked through the company's website. To book a ride through the website, the customer must create a user profile ("the profile", "the account", "user account").</p>
                <p>Some of the information provided during the creation of this profile may be considered sensitive by some users, as may the ride history once the user has taken rides using the service. The company will make best efforts to ensure information security and privacy of this sensitive data. The company is not liable if sensitive or private data is obtained by malicious actors ("threat actors", "adversaries") despite the company's best efforts to stop this from happening.</p>
                <p>The company may also utilize third party software, services or other tools not developed by the company to process some data, including customer data. The privacy and data usage policies of these services or tools will be reviewed before they are utilized and best efforts will be made to configure all of them as securely as possible. The company is not liable if a third party does, intentionally or unintentionally, disclose sensitive data despite best efforts on the company's part to ensure that they will not.</p>
                <h4>2. Data Portability and Data Deletion</h4>
                <p>The company will honor requests to delete personal data from users. The company will also provide all data they have about a user to that user upon request, in an industry standard format wherever possible. This service will be offered free of charge. The company will comply with all applicable laws in the jurisdiction they are incorporated in (the State of California) with respect to customer data.</p>
                <h4>3. Data Collection and Storage</h4>
                <p>Ridio collects data in a variety of ways, both directly and indirectly. These include but are not limited to data provided by users through website or application forms, website analytics data, vehicle telemetry collected during rides, and information provided in correspondence between Ridio and other parties.</p>
                <p>The company will primarily store data in Amazon Web Services (AWS), specifically in the us west-1 region wherever possible, or another AWS region in the United States of America if not possible. Data may also be stored in other locations. Best efforts will be made to utilize industry standard encryption of data wherever possible.</p>
                <h4>4. Data Sharing</h4>
                <p>Ridio will not share customer data with other customers unless asked to by the customer whose data it is. Ridio will not sell customer data for advertising or other purposes. Ridio will provide data to law enforcement agencies if served with applicable legal process requiring them to do so, or if otherwise required to by law. Ridio will share the minimum amount of customer data needed to ensure quality service with drivers.</p>
                <h4>4. Modification of Terms</h4>
                <p>The company reserves the right to modify any or all of these terms at any time. Any such modifications will be communicated to users through the best available means of communication on file for that user. The modified terms will also be promptly posted on the website.</p>
            </div>
        </div>
    )
}

export default PrivPol;