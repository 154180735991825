import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { NavLink } from "react-router-dom";
import '../App.css'; 

const ContactForm = () => {

  const [formFilled, setFormFilled] = useState(false);
  const [errors, setErrors] = useState({});

  const [toSend, setToSend] = useState({
    from_name: '',
    message: '',
    reply_to: '',
  });

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validateForm = () => {
    let formErrors = {};
    if (!toSend.from_name) formErrors.from_name = 'Name is required';
    if (!toSend.message) formErrors.message = 'Message is required';
    if (!toSend.reply_to || !validateEmail(toSend.reply_to)) {
      formErrors.reply_to = 'Valid email is required';
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setToSend({ ...toSend, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // sends to Luke for testing: 
      // emailjs.send('service_hzjgrh8', 'template_14bc0gj', toSend, 'u-4V-WwiIRkUkd0Ml')
      // to send to Darin instead, comment out previous line, and uncomment following send code
      emailjs.send('contact_service','contact_form', toSend, 'u-4V-WwiIRkUkd0Ml')        
        .then((result) => {
          console.log('SUCCESS!', result.text);
          setFormFilled(true);
        }, (error) => {
          console.log('FAILED...', error.text);
        });
    }
  };

  return (
    <div className='about-content'>
      <div className="bk-box">
        <NavLink className="form-b selected" to="/book">Book a Ride</NavLink>
      </div>

      <div>
        {formFilled ? (
          <label className="form-area">
            <label className="form-e contact-response">
              <p>Thank you!</p>
            </label>
          </label>
        ) : (
          <form className="form-area" onSubmit={handleSubmit}>
            <label className="form-e">
              <h3 className='auth-header'>Questions? Comments? Reach our team here:</h3>

              <input
                className="i-box"
                type='text'
                name='from_name'
                placeholder='Name'
                value={toSend.from_name}
                onChange={handleChange}
              />
              {errors.from_name && <p className="contact-error">{errors.from_name}</p>}
            </label>

            <label className="form-e">
              <input
                className="i-box"
                type='text'
                name='reply_to'
                placeholder='Email'
                value={toSend.reply_to}
                onChange={handleChange}
              />
              {errors.reply_to && <p className="contact-error">{errors.reply_to}</p>}
            </label>

            <label className="form-e">
              <input
                className="i-box comment-box"
                type='text'
                name='message'
                placeholder='Message'
                value={toSend.message}
                onChange={handleChange}
              />
              {errors.message && <p className="contact-error">{errors.message}</p>}
            </label>

            <label className="form-e">
              <button className="form-b unselected" type="submit">{'Send'}</button>
            </label>
          </form>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
