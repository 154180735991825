import React, { useState } from 'react';
import '../App.css'; 
import { NavLink } from "react-router-dom";

function BookButton() {
    return (
        <div className="book-button">
        <NavLink className="form-b selected" to="/book">Book a Ride</NavLink>
      </div>
    )
}

export default BookButton;