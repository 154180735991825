import React from "react";
import { NavLink, withRouter } from "react-router-dom";

const Page404 = () => (
  <div className="error">
    <h1>Oops</h1>
    <p>This page isn't working. Sorry!</p>
    <div className="book-button">
      <NavLink className="App-link" to="/">Go to Ridio Homepage</NavLink>
    </div>
  </div>
);

export default Page404;

// className="App-link"