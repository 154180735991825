import React, { useState } from 'react';
import '../App.css'; 
import { NavLink } from "react-router-dom";

function About() {
  const [hover, setHover] = useState(false);
  const className = hover ? 'scroll-effect' : 'static';
  const overlayClass = hover ? 'overlay-active' : 'overlay-hidden';

  const handleTouchStart = () => {
    setHover(true);  // Activate the effect on touch start
  };

  return (
    <div className='about-content'>

      <div className="bk-box">
        <NavLink className="form-b selected" to="/book">Book a Ride</NavLink>
      </div>

      <div className="overlay-wrap">
        <div className="over">
          {/* Desktop hover effect */}
          <div className={className} onMouseEnter={() => setHover(true)}>
            <div className="image-wrap">
              <img src={'/rid-pics/IMG_2400_crop.jpeg'} className="car-pic" alt="pic of car" />
            </div>
          </div>
        </div>

        {/* Overlay for desktop */}
        <div className={`overlay ${overlayClass}`} onMouseEnter={() => setHover(true)}>
          <div className="overlay-text">
            <p>Where sustainable travel doesn’t sacrifice user experience.</p> 
            <p>Ridio's all-electric vehicles and professional drivers will cut carbon emissions, providing a premium and reliable experience.</p>
          </div>
        </div>
      </div>

      {/* Mobile Touch Effect */}
      <div className="mobile-home">
        <div className="over">
          <div className={className} onTouchStart={handleTouchStart}>
            <div className="image-wrap">
              <img src={'/rid-pics/IMG_2400_crop.jpeg'} className="car-pic" alt="pic of car" />
            </div>
          </div>
        </div>

        {/* Overlay for mobile */}
        <div className={`overlay ${overlayClass}`} onTouchStart={handleTouchStart}>
          <div className="overlay-text">
            <p>Where sustainable travel doesn’t sacrifice user experience.</p> 
            <p>Ridio's all-electric vehicles and professional drivers will cut carbon emissions, providing a premium and reliable experience.</p>
          </div>
        </div>
      </div>

      <div class='about-text'>
        <h3>Ridio's fleet of electric vehicles sustainably enables freedom of mobility without compromise, offering a premium car service to destinations underserved by public transit.</h3>
        <p>Ridio ensures that each trip is better for the rider, driver, and our planet.</p>
          
        <p>We combine the reliability and comfort of a black car service with the technology, convenience, and competitive pricing of a modern ride share. Launching in the greater Bay Area, our mission is to lead the transition to an era where sustainable travel doesn’t sacrifice user experience.</p>            
        <ul class="b-points">
            <li>Riders will be able to track their carbon emission reduction and earn rewards with each ride.</li>
            <li>Our booking system offers round trip services and standardized pricing.</li>
            <li>Our drivers have professional driver safety training.</li> 
            <li>Drivers receive a living wage.</li> 
        </ul>
                        
        <p>We believe that part of being a better car service requires that we always treat both our customers and drivers with respect.</p>
            
        <h3>Our Team</h3>
        <p>Darin Smith, Julian McClanahan, and Teresa Lee founded Ridio as a Benefit Corporation in 2022 with a vision to disrupt the transportation industry with a focus on sustainability. As an alumni of Cleantech Open, the largest clean technology accelerator program in the world, Ridio began operations in 2024.</p>
      </div>
      <div className="cohort-box">
        <img src={'/rid-logos/cohort_logo.png'} className="cohort" alt="a green logo with three words: clean tech open" />
      </div> 
    </div>
  );
}

export default About;
