import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { loadStripe } from '@stripe/stripe-js';
import Dropdown from './Dropdown'; 

const stripePromise = loadStripe("pk_live_51NgtgwA91KW339c4m9VPtbTVH0lrZOJh6TmGV0fuFMWYYLXV1JsNw319okGhzPwP4koaJm67Ynh7x6169WQJ7yhy00fUslHkbJ");

function Store(CartStorage) {
    // temporary storage of selected merch data, prior to saving to cart
    const [sticker, setSticker] = useState('0');
    const [shirtSize, setShirtSize] = useState('');
    const [shirtColor, setShirtColor] = useState('');
    const [shirtQuantity, setShirtQuantity] = useState('');

    // merch data saved in cart using localStorage
    const [cartSticker, setCartSticker] = useState(localStorage.getItem('cartSticker') || ''); // cost
    const [cartStickerQuant, setCartStickerQuant] = useState(localStorage.getItem('cartStickerQuant') || '');
    const [cartShirts, setCartShirts] = useState(JSON.parse(localStorage.getItem('cartShirts')) || []); // multiple shirts
    const [cart, setCart] = useState(localStorage.getItem('cart') || '');

    // track when an item is added to the cart
    const [stickerAdded, setStickerAdded] = useState(false);
    const [shirtAdded, setShirtAdded] = useState(false);

    // State for controlling popup visibility
    const [cartPopup, setCartPopup] = useState(false); 

    // Stores state for cart editing
    const [isEditing, setIsEditing] = useState(false);
  
    // sets price of merch
    const stickerPrice = (sticker * 2);
    const shirtPrice = (shirtQuantity * 25);

    // sends sticker data to cart
    const saveSticker = (event) => {
        event.preventDefault();
        setCartSticker(+stickerPrice);
        setCartStickerQuant(+sticker);
        setCart(+shirtPrice + +stickerPrice);
        setStickerAdded(true); // Mark the sticker as added
    };

    const saveShirt = (event) => {
        event.preventDefault();

        const newShirt = {
            price: shirtPrice,
            quantity: shirtQuantity,
            size: shirtSize,
            color: shirtColor,
        };

        setCartShirts(prevShirts => {
            const updatedShirts = [...prevShirts, newShirt];
            localStorage.setItem('cartShirts', JSON.stringify(updatedShirts));
            return updatedShirts;
        });

        setCart(prevCart => +prevCart + +shirtPrice + +stickerPrice);
        setShirtAdded(true); // Mark the shirt as added
    };

    // Clears cart
    const clearCart = () => {
        localStorage.clear();
        setCartSticker('');
        setCartStickerQuant('');
        setCartShirts([]); // Clear the shirts array
        setCart('');
    };

    // Removes stickers from cart
    const handleRemoveSticker = () => {
        setCartStickerQuant(0);
        setCartSticker(0);
        setCart(prevCart => prevCart - stickerPrice);
    };
    
    // Removes shirts from cart
    const handleRemoveShirt = (index) => {
        setCartShirts(prevShirts => {
            const updatedShirts = prevShirts.filter((_, i) => i !== index);
            localStorage.setItem('cartShirts', JSON.stringify(updatedShirts));
            return updatedShirts;
        });
    
        const removedShirt = cartShirts[index];
        setCart(prevCart => prevCart - removedShirt.price * removedShirt.quantity);
    };

    // Use useEffect to update local storage whenever the state changes
    useEffect(() => {
        localStorage.setItem('cart', cart);
        localStorage.setItem('cartSticker', cartSticker);
        localStorage.setItem('cartStickerQuant', cartStickerQuant);
        localStorage.setItem('cartShirts', JSON.stringify(cartShirts));
    }, [cart, cartSticker, cartStickerQuant, cartShirts]);

    const handleCheckout = async (event) => {
        event.preventDefault();
    
        // Load Stripe
        const stripe = await stripePromise;
    
        // Create the line items for Stripe Checkout
        const lineItems = [];
    
        if (cartStickerQuant > 0) {
            lineItems.push({
                price_data: {
                    currency: 'usd',
                    product_data: {
                        name: 'Ridio Sticker',
                    },
                    unit_amount: 200, // Amount in cents (e.g. $2.00)
                },
                quantity: Number(cartStickerQuant), // Ensure quantity is a number
            });
        }
    
        if (cartShirts.length > 0) {
            cartShirts.forEach(shirt => {
                if (!shirt.color || !shirt.size) {
                    alert('Please select both a color and size for the shirt.');
                    return;
                }

                lineItems.push({
                    price_data: {
                        currency: 'usd',
                        product_data: {
                            name: `Ridio Shirt (Color: ${shirt.color}, Size: ${shirt.size})`,
                        },
                        unit_amount: 2500, // Amount in cents (e.g. $25.00)
                    },
                    quantity: Number(shirt.quantity),
                });
            });
        }
    
        console.log("Line items being sent to checkout: ", lineItems);
    
        if (lineItems.length === 0) {
            alert('Your cart is empty!');
            return;
        }
    
        // Call your backend to create a Stripe Checkout session
        try {
            const response = await fetch('https://66ycb6hzw6a3xcj4i2pl67pfcy0traln.lambda-url.us-east-1.on.aws/store_checkout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    lineItems: lineItems,
                }),                
            });
    
            const session = await response.json();
    
            if (response.status !== 200) {
                throw new Error(`Error creating checkout session: ${session.error || response.statusText}`);
            }
    
            // Redirect to Stripe Checkout
            const result = await stripe.redirectToCheckout({
                sessionId: session.id,
            });
    
            if (result.error) {
                alert(result.error.message);
            } else {
                // Clear the cart after a successful redirect
                clearCart();
            }
        } catch (error) {
            alert(`Error creating checkout session: ${error.message}`);
        }
    };    

    return (
        <div>
            <div className="bk-box">
                <NavLink className="form-b selected" to="/book">Book a Ride</NavLink>
            </div>

            <form className='store'>

                <div className='store-item sticker-box'>
                    <div className="store-pic-bucket">
                        <img className="store-pic sticker" src={'/rid-pics/sticker.png'} alt="Sticker featuring Ridio logo" />
                    </div>

                    <div className='store-form'>
                        <div className='form-'>
                            <h5>Super awesome Ridio stickers!</h5>
                            <p>2$ per pack of 5.</p>
                        </div>

                        <Dropdown
                            label="Select quantity"
                            options={["1", "2", "3", "4", "5"]}
                            selected={sticker}
                            onChange={(value) => {
                                setSticker(value);
                                setStickerAdded(false); // Reset "Added" button state
                                }
                            }
                        />

                        <p><button className="form-b store-b selected" onClick={saveSticker}>
                            {stickerAdded ? "Added" : "Add to Cart"}
                        </button></p>
                    </div>
                </div>

                {/* Shirt Section */}
                <div className='store-item'>
                    <div className="store-pic-bucket">
                        <img className="store-pic shirt" src={'/rid-pics/shirt.jpeg'} alt="Two Ridio founders wearing Ridio shirts" />
                    </div>
                    
                    <div className="store-form">
                        <h5>Super awesome Ridio shirts!</h5>
                        <p>25$ each.</p>

                        {/* uses component from Dropdown.js file */}
                        <Dropdown
                            label="Select size"
                            options={["XL", "L", "M", "S", "XS"]}
                            selected={shirtSize}
                            onChange={(value) => {
                                setShirtSize(value);
                                setShirtAdded(false); 
                                }
                            }                        
                        />

                        <Dropdown
                            label="Select color"
                            options={["Black", "White"]}
                            selected={shirtColor}
                            onChange={(value) => {
                                setShirtColor(value);
                                setShirtAdded(false); 
                                }
                            } 
                        />

                        <Dropdown
                            label="Select quantity"
                            options={["1", "2", "3", "4", "5"]}
                            selected={shirtQuantity}
                            onChange={(value) => {
                                setShirtQuantity(value);
                                setShirtAdded(false); 
                                }
                            } 
                        />

                        <button className="form-b store-b selected" onClick={saveShirt}>
                            {shirtAdded ? "Added" : "Add to Cart"}
                        </button>
                    </div>
                </div>
            </form>

            {/* Cart Button */}
            <div className='cart-box'>
                <button className="form-b store-b selected" onClick={() => setCartPopup(true)}>Go to Cart</button>
            </div>

            {/* // Cart Popup */}
            {cartPopup && (
                <div className="cart-popup">
                    <div className="cart-content">
                        <span className="close" onClick={() => setCartPopup(false)}>&times;</span>
                        <h2>Cart</h2>

                        {cartStickerQuant > 0 && (
                            <div>
                                <div className='cart-i'>
                                Stickers - {cartStickerQuant} pack(s) of Ridio stickers, {cartSticker}$
                                    {isEditing && (
                                        <button className='form-b remove-b' onClick={handleRemoveSticker}>Remove</button>
                                    )}
                                </div>
                            </div>
                        )}

                        {cartShirts.length > 0 && cartShirts.map((shirt, index) => (
                            <div key={index}>
                                <div className='cart-i'>
                                    {shirt.quantity} {shirt.size} {shirt.color} Ridio T-shirt(s)! {shirt.price}$
                                    {isEditing && (
                                        <button  className='form-b remove-b' onClick={() => handleRemoveShirt(index)}>Remove</button>
                                    )}
                                </div>
                            </div>
                        ))}

                        <p>Cart total: {cart}$</p>
                        <button className="form-b store-b clear-button" onClick={() => setIsEditing(prev => !prev)}>{isEditing ? "Done Editing" : "Edit Cart"}</button>
                        <button className="form-b store-b clear-button" onClick={clearCart}>Clear Cart</button>
                        <button className="form-b store-b selected" onClick={handleCheckout}>Checkout</button>
                    </div>
                </div>
            )}
            <div className="extra-blue"></div>
        </div>
    );
}

export default Store;
