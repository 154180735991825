import React, { useEffect, useState } from 'react';
import '../App.css'; 
import { useNavigate } from 'react-router-dom';
import { NavLink, withRouter } from "react-router-dom";

const Success = () => (
  <div className="success-page">
    <div className="success-container">
      <h2>Success!</h2>
      <p>Thank you for your purchase.</p>
      {/* <div className="book-button"> */}
        <NavLink className="form-b unselected" to="/">Ridio Homepage</NavLink>
      {/* </div> */}
    </div>
  </div>
);

export default Success;